<template>
<div id="team">
  <div class="container-fluid margin-section">
      <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="text f-2 fw-bold mb-3 text-uppercase text-center">{{ $t('team.title') }}</div>
              <div class="text f-6 text-center">
                <p>{{ $t('team.description') }}</p>
              </div>
            </div>
              <div class="col-lg-3 col-md-12 member text-center d-flex flex-lg-column justify-content-md-start" v-for="(member, index) in team" :key="index">
                <div><img :src="member.image" class="rounded-circle" alt="Team member photo" width="70" height="70"/></div>
                <div class="text-align">
                  <div class="mt-3"><a class="text fs-6 text-orange text-decoration-none" :href="member.url" target="_blank"> {{ member.name }}</a></div>
                  <div class="text fs-6 mt-2">{{ member.job_description }}</div>
                </div>
              </div>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Team',
  data () {
    return {
      team: [
        {
          image: require('@/assets/avatars/bururito.jpeg'),
          name: '@bururito',
          url: 'https://www.instagram.com/bururito/?hl=es',
          job_description: 'Content Manager'
        },
        {
          image: require('@/assets/avatars/ferpe.jpeg'),
          name: 'Fero P',
          url: 'https://twitter.com/Fero_arts',
          job_description: 'Wolf Alpha Artist'
        },
        {
          image: require('@/assets/avatars/memo.jpeg'),
          name: 'Guillermo Reyes',
          url: 'https://twitter.com/memo_mx',
          job_description: 'Project Lead'
        },
        {
          image: require('@/assets/avatars/herrj.jpg'),
          name: 'Herr J',
          url: 'https://www.youtube.com/watch?v=DLzxrzFCyOs',
          job_description: 'WereWolf Developer'
        },
        {
          image: require('@/assets/avatars/ralts00.jpeg'),
          name: 'Jonathan Coutiño',
          url: 'https://twitter.com/ralts00',
          job_description: 'Logo & Landing Designer'
        },
        {
          image: require('@/assets/avatars/sule.jpeg'),
          name: 'Sulemy Rodríguez',
          url: 'https://www.linkedin.com/in/sulemy-rodr%C3%ADguez-45841996/',
          job_description: 'Frontend Developer'
        },
        {
          image: require('@/assets/avatars/carolina.jpeg'),
          name: 'Carolina Millan',
          url: 'https://twitter.com/CarolinaMillan',
          job_description: 'Global Networking & Strategy'
        },
        // {
        //   image: require('@/assets/avatars/pedro.jpeg'),
        //   name: 'Pedro Crypto',
        //   url: 'https://twitter.com/pedro_cripto',
        //   job_description: 'Business Developer'
        // }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .member {
    margin-top: 80px;
  }

  @media (max-width: 768px){
    .member {
      margin-top: 40px;
    }

    img {
      margin-right: 24px;
    }

    .text-align {
      text-align: left;
    }
  }
</style>
