<template>
  <div>
    <div id="gallery" class="margin-section">
      <div id="scrollGallery" class="scroll d-flex overflow-scroll margin-gallery">
        <div :class="[index === 0  ? 'space-start' : index === galleryWolf.length - 1 ? 'space-end space-photo' : 'space-photo' ]" v-for="(item, index) in galleryWolf" :key="index">
          <img :src="item">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Gallery',
  data() {
    return {
      galleryWolf: [
        require('@/assets/wolf_1.jpg'),
        require('@/assets/wolf_2.jpg'),
        require('@/assets/wolf_3.jpg'),
        require('@/assets/wolf_4.jpg'),
        require('@/assets/wolf_5.jpg'),
        require('@/assets/wolf_6.jpg'),
        require('@/assets/wolf_7.jpg'),
        require('@/assets/wolf_8.jpg')
      ]
    }
  },
  created() {
    this.executeAnimation;
  },
  computed: {
    executeAnimation() {
      let value = null;
      let valuePrevious = null;
      let valueGallery = null;
      let valueElement = null;
      let isVisibleGallery = false;
     
      window.addEventListener('scroll', () => {
        value = window.scrollY;

        if(!valueElement) {
          valueElement = document.documentElement;
        }

        if(!valueGallery) {
          valueGallery = document.getElementById('gallery');
        }

        let scrolling = document.getElementById('scrollGallery');
        const postTopView = window.scrollY;
        const posButView = postTopView + window.innerHeight;
        const elementTop = scrolling.offsetTop;
        const elementBottom = elementTop + scrolling.offsetHeight
        isVisibleGallery = ((elementBottom < posButView && elementBottom > postTopView) || (elementTop > postTopView && elementTop < posButView));
        
        if (isVisibleGallery) {
          if (value < valuePrevious) {
            scrolling.scrollLeft -= 3;
          } else {
            scrolling.scrollLeft += 3;
          }
        }
        
        valuePrevious = value;
        return null
      })
      return null
    }
  }
}
</script>

<style lang="scss" scoped>

.space-photo {
  padding-left: 20px;
}

.div {
  width: 20px;
}

.space-start {
  margin-left: 8%;
}

.space-end {
  margin-right: 8%;
}

.scroll {
  overflow: hidden !important;
}

img {
  border-radius: 8px;
  width: 207px;
  height: 207px;
}

@media (max-width: 768px){
  img {
    border-radius: 8px;
    width: 160px;
    height: 160px;
  }
}
</style>