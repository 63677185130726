<template>
  <div id="roadmap">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="text fs-2 title">{{ $t('roadmap.title') }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-10 col-md-5 col-lg-5 offset-1 offset-md-1 offset-lg-1">
          <p>{{ $t('roadmap.subtitle') }}</p>
        </div>
      </div>
      <div id="first-section" class="row justify-content-center">
        <div id="first-goals" class="col-10 col-md-5 col-lg-5">
          <h4>{{ percentage._10 }}</h4>
          <p>{{ $t('roadmap.goals._10') }}</p>
          <h4>{{ percentage._20 }}</h4>
          <p>{{ $t('roadmap.goals._20') }}</p>
          <h4>{{ percentage._40 }}</h4>
          <p>{{ $t('roadmap.goals._40') }}</p>
        </div>
        <div id="img-comic" class="col-10 col-md-5 col-lg-5 align-self-md-center">
          <img src="../assets/comic.jpeg" class="img-fluid" alt="Werewolf comic">
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10">
          <h4>{{ percentage._50 }}</h4>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10">
          <p class="text-justify">{{ $t('roadmap.goals._50') }}</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div id="img-shirt" class="col-10">
          <img src="../assets/tshirt.png" class="img-fluid" alt="Tshirt">
        </div>
      </div>
      <div class="row">
        <div class="col-10 offset-1 d-block d-sm-none">
          <h4>{{ percentage._60 }}</h4>
          <p>{{ $t('roadmap.goals._60') }}</p>
        </div>
        <div id="img-mobile-gameboard" class="col-10 offset-1 d-block d-sm-none">
          <img src="../assets/gameboard.jpg" alt="Gameboard">
        </div>
         <div class="col-10 offset-1 d-block d-sm-none">
          <h4>{{ percentage._70 }}</h4>
          <p>{{ $t('roadmap.goals._70') }}</p>
        </div>
        <div class="col-md-5 col-lg-5 offset-md-1 offset-lg-1 d-none d-sm-block">
          <h4>{{ percentage._60 }}</h4>
          <p>{{ $t('roadmap.goals._60') }}</p>
          <h4>{{ percentage._70 }}</h4>
          <p>{{ $t('roadmap.goals._70') }}</p>
        </div>
        <div id="img-gameboard" class="col-md-4 col-lg-4 offset-md-1 offset-lg-1 d-none d-sm-block">
          <img src="../assets/gameboard.jpg" alt="Gameboard">
        </div>
      </div>
      <div class="row">
        <div class="col-10 offset-1 d-block d-sm-none">
          <h4>{{ percentage._80 }}</h4>
          <p>{{ $t('roadmap.goals._80') }}</p>
        </div>
        <div class="col-10 offset-1 d-block d-sm-none">
          <h4>{{ percentage._90 }}</h4>
          <p>{{ $t('roadmap.goals._90') }}</p>
        </div>
        <div id="img-mobile-chest" class="col-10 offset-1 d-block d-sm-none">
          <img src="../assets/cofre.png" alt="Chest">
        </div>
         <div class="col-10 offset-1 d-block d-sm-none">
          <h4>{{ percentage._100 }}</h4>
          <p>{{ $t('roadmap.goals._100') }}</p>
        </div>
        <div class="col-md-5 col-lg-5 offset-md-1 offset-lg-1 d-none d-sm-block">
          <h4>{{ percentage._80 }}</h4>
          <p>{{ $t('roadmap.goals._80') }}</p>
          <h4>{{ percentage._90 }}</h4>
          <p>{{ $t('roadmap.goals._90') }}</p>
          <h4>{{ percentage._100 }}</h4>
          <p>{{ $t('roadmap.goals._100') }}</p>
        </div>
        <div id="img-chest" class="col-md-4 col-lg-4 offset-md-1 offset-lg-1 d-sm-block d-none">
          <img src="../assets/cofre.png" alt="Chest">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoadMap',
  data() {
    return {
      percentage: {
        _10: '10%',
        _20: '20%',
        _40: '40%',
        _50: '50%',
        _60: '60%',
        _70: '70%',
        _80: '80%',
        _90: '90%',
        _100: '100%'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/styles/vars';

  .title {
    font-family: 'NotoSans';
    font-weight: bold;
    margin-bottom: 16px;
  }

  h4 {
    color: $red;
    font-weight: bold;
    font-family: 'NotoSans';
  }

  p {
    text-align: justify;
    font-size: 14px;
    margin-bottom: 32px;
  }

   #img-shirt {
    padding-bottom: 40px;
    img {
      border-radius: 8px;
      width: 100%;
    }
  }

  #img-comic {
    margin-bottom: 24px;
    text-align: right;
    img {
      border-radius: 4px;

      @media (min-width: 768px) {
        width: 80%;
      }

      @media (min-width: 992px) {
        width: 50%
      }
    }
  }

  #img-mobile-gameboard img, #img-mobile-chest img{
    width: 100%;
    border-radius: 8px;
    margin-bottom: 32px;
  }

  #img-gameboard img {
    width: 100%;
    border-radius: 4px;
  }

  #img-chest img {
    width: 100%;
  }
</style>
