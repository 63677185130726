export default {
  moveToSection(idElement) {
    const positionElement = this.getAbsoluteElementPosition(idElement)
    window.scroll({
      top: positionElement.top 
    })
  },

  getAbsoluteElementPosition(element) {
    if (typeof element == "string")
      element = document.getElementById(element)
      
    if (!element) return { top: 0, left: 0 }
    
    let y = 0
    let x = 0
    while (element.offsetParent) {
      x += element.offsetLeft
      y += element.offsetTop
      element = element.offsetParent
    }
    return { top: y, left: x }
  }
}