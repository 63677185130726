<template>
  <button v-if="!buttonTitle"
    type="button" 
    class="btn button-connect button-mobile" 
    :disabled="isLoadingWallets" 
    @click="handleConnectWallet">
      {{label}}
  </button>
  <div v-else class="wallet-address text"><span>{{ buttonTitle }}</span></div>
</template>

<script>
import { mapActions } from 'vuex';
import Web3 from 'web3'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'

export default {
  props: {
    wallets: {
      type: Array,
    },
    label: {
      type: String,
      required: true
    },
    metamaskUrl: {
      type:String,
      default:() => ('https://metamask.io/download.html')
    }
  },
  emits: ['update:wallets'],
  methods: {
    ...mapActions(['registeredWallet']),
    async handleConnectWallet() {
      if (this.isMobile()) {
        if (typeof window.ethereum !== 'undefined') {
          this.webConnect()
        } else {
          this.mobileConnect()
        }
      } else {
        this.webConnect()
      }
    },
    isMobile () {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ]
    
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem)
      })
    },
    async mobileConnect () {
      try {
        const web3Modal = new Web3Modal({
          network: process.env.VUE_APP_WEB_NETWORK,
          cacheProvider: false,
          providerOptions: {
            walletconnect: {
              package: WalletConnectProvider,
              options: {
                infuraId: process.env.VUE_APP_INFURA_ID
              }
            }
          }
        })
        const provider = await web3Modal.connect()
        this.$emit('update:wallets', provider.accounts) 
      } catch (error) {
        console.error('error::::', error)
      }
    },
    async webConnect () {
      if(this.ethereum && this.ethereum.isMetaMask) {
        this.isLoadingWallets = true
        try {
          await this.ethereum.request({ method: 'eth_requestAccounts' })
          const web3 = new Web3(this.ethereum)
          const newWallets = await web3.eth.getAccounts()
          this.$emit('update:wallets', newWallets)
        } catch (error) {
          console.error(error)
        }
        this.isLoadingWallets = false
      } else {
        window.open(this.metamaskUrl)
      }
    }
  },
  data () {
    return {
      isLoadingWallets: false,
    }
  },
  computed: {
    buttonTitle() {
      if(this.wallets && this.wallets.length) {
        const walletAddress = this.wallets[0]
        this.registeredWallet(walletAddress);
        const start = walletAddress.slice(0, 3)
        const end = walletAddress.slice(walletAddress.length - 3, walletAddress.length)
        return `${start}...${end}`
      } 
      return null;
    },
    ethereum() {
      return window.ethereum
    }
  }
}
</script>
