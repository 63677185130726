<template>
  <div class="container-fluid">
    <div class="row background-grey">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div>
              <img src="../assets/logo-large.svg" alt="logo-desktop">
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="d-flex justify-content-lg-end">
              <div class="link d-flex flex-column">
                <div class="text fs-6 pb-4 fw-bold">Quick Links</div>
                <div class="pb-4" v-for="(itemQuickLink, index) in quicksLinks" :key="index">
                  <a class="text fs-6" :href="itemQuickLink.url" target="_blank">{{ itemQuickLink.name }}</a>
                </div>
              </div>
              <div class="link jump d-flex flex-column">
                <div class="text fs-6 pb-4 fw-bold">Jump to</div>
                <div class="pb-4" v-for="(itemJumpTo, index) in jumpTo" :key="index">
                  <a class="text fs-6" @click="moveToSelectedSection(itemJumpTo.section_id)">{{ itemJumpTo.name }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row">
      <div class="col-12 build">
        <div class="text text-center">
          <span>Built by WOLFCorp, All Rights Reserved</span>
          <span> &copy; </span>
          <span>{{ currentDate }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import utilsMoveToSection from '@/utils/moveToSection';
import { DISCORD_URL, TWITTER_URL } from '@/utils/constants';

export default {
  name: 'Footer',
  data () {
    return {
      reserved: 'Built by WOLFCorp, All Rights Reserved &copy; 2021',
      quicksLinks: [
        { name: 'Discord', url: DISCORD_URL },
        { name: 'Twitter', url: TWITTER_URL },
        // { name: 'Opensea', url:'#' },
      ],
      jumpTo: [
        { name: 'Gallery', section_id: 'gallery' },
        { name: 'Mint', section_id: 'mint' },
        { name: 'Roadmap', section_id: 'roadmap' },
      ],
      currentDate: new Date().getFullYear()
    }
  },
  methods: {
    moveToSelectedSection(id) {
      utilsMoveToSection.moveToSection(id);
    }
  }
}
</script>
<style lang="scss" scoped>
  img {
    margin-top: 98px;
    margin-bottom: 98px;
  }

  .link {
    margin-top: 80px;
    margin-left: 80px;
  }

  .build {
    margin-top: 48px;
    margin-bottom: 48px;
    font-size: 16px;
  }

  @media (max-width: 768px){
    img {
      margin-top: 64px;
      margin-bottom: 40px;
      margin-left: 40px;
      margin-right: 40px;
    }

    .link {
      width: 100%;
      margin-top: 0px;
      margin-left: 0px;
    }

    .jump {
     margin-left: 30%;
    }

    .build {
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 14px;
    }
  }
</style>
