<template>
  <div id="community" class="margin-section">
  <div class="container-fluid background">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <div class="text fs-2 fw-bold text-uppercase lh-base">
            {{ $t('community.title') }}
          </div>
          <div class="text fs-6 mt-3" >{{ $t('community.description') }}</div>
          <div class="margin-bottom">
            <a :href="discord_url"  class="btn text fs-6 button-blue" target="_blank">
              {{ $t('community.button') }}
            </a>
          </div>
          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
  </div>
</template>

<script>
import { DISCORD_URL } from "@/utils/constants";

export default {
  name: 'Community',
  data () {
    return {
      discord_url: DISCORD_URL
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/vars';

  .background {
    background: url(../assets/community.jpg);
  }

  .text {
    margin-top: 72px;
  }

  .margin-bottom {
    margin-bottom: 72px;
  }

  @media (max-width: 768px){
    .background {
      background: url('../assets/community-min.jpg');
    }

    .text {
      margin-top: 56px;
    }

    .margin-bottom {
      margin-bottom: 56px;
    }
  }
  
  a {
    transition: all .2s;
    &:hover {
      color: $white !important;
      transform: scale(1.04);
    }
  }
</style>