import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/general.scss'
import Toaster from '@meforma/vue-toaster'
import { createI18n } from 'vue-i18n'

import navbarEn from './locales/en/navbar.json'
import navbarEs from './locales/es/navbar.json'
import storyEn from './locales/en/storyTelling.json'
import storyEs from './locales/es/storyTelling.json'
import roadmapEn from './locales/en/roadmap.json'
import roadmapEs from './locales/es/roadmap.json'
import teamEN from './locales/en/team.json'
import teamEs from './locales/es/team.json'
import communityEn from './locales/en/community.json'
import communityEs from './locales/es/community.json'

const messages = {
  en: {
    navbar: navbarEn,
    storyTelling: storyEn,
    roadmap: roadmapEn,
    team: teamEN,
    community: communityEn
  },
  es: {
    navbar: navbarEs,
    storyTelling: storyEs,
    roadmap: roadmapEs,
    team: teamEs,
    community: communityEs
  }
}

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'es',
  messages
})
const app = createApp(App)

app.use(store)
app.use(router)
app.use(Toaster)
app.use(i18n)

app.mount('#app')
