<template>
  <div>
    <AppNavbar @wallets="handledWallets"/>
  </div>
  <div class="contenedor-general" data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="0" tabindex="0">
    <Hero />
    <Storytelling />
    <RoadMap />
    <!-- <PreSaleCounter /> -->
    <MintPrincipal :wallets="wallets" />
    <Transformation />
    <Gallery />
    <Team />
    <Community />
    <Footer />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar';
import Hero from '@/components/Hero';
import Storytelling from '@/components/Storytelling';
import Team from '@/components/Team';
import Community from '@/components/Community';
import Footer from '@/components/Footer';
import Gallery from '@/components/Gallery';
import RoadMap from "@/components/RoadMap";
import Transformation from "@/components/Transformation";
// import PreSaleCounter from '@/components/PreSaleCounter';
// import MintPrincipal from '@/components/MintPrincipal';

export default {
  name: 'Home',
  components: {
    AppNavbar,
    Hero,
    Storytelling,
    Gallery,
    Team,
    Community,
    Footer,
    RoadMap,
    Transformation,
    // MintPrincipal
    // PreSaleCounter
  },
  data () {
    return {
      wallets: []
    }
  },
  methods: {
    handledWallets (valueWallets) {
      this.wallets = valueWallets
    }
  }
}
</script>
<style lang="scss" scoped>
  @font-face {
    font-family: 'NotoSans';
    src: url('../assets/fonts/NotoSansMono-Bold.ttf')
  }
</style>
