<template>
  <div class="d-flex justify-content-center margin-section">
    <img src='../assets/transformation.gif' />
  </div>
</template>
<script>
export default {
  name: 'Transformation'
}
</script>
<style lang="scss" scoped>
img {
  border-radius: 8px;
  width: 50%;
  height: 50%;
}
</style>
