import { createStore } from 'vuex'
import axios from 'axios';

const baseUrl = process.env.VUE_APP_BACKEND_URL

export default createStore({
  state: {
    walletExistsWhiteList: false,
    placesAvailable: -1,
    error: '',
    registeredUserInformation: {},
    isUpdatePreSale: false
  },
  getters: {
    walletExistsWhiteList: (state) => state.walletExistsWhiteList,
    placesAvailable: (state) => state.placesAvailable,
    error: (state) => state.error,
    registeredUserInformation: (state) => state.registeredUserInformation,
    isUpdatePreSale: (state) => state.isUpdatePreSale
  },
  mutations: {
    setWalletExistsWhiteList (state, infoResponse) {
      state.walletExistsWhiteList = infoResponse
    },
    setPlacesAvailable(state, infoResponse) {
      state.placesAvailable = infoResponse
    },
    setError(state, infoResponse) {
      state.error = infoResponse
    },
    setRegisteredUserInformation(state, infoResponse) {
      state.registeredUserInformation = infoResponse
    },
    setIsUpdatePreSale(state, infoResponse) {
      state.isUpdatePreSale = infoResponse;
    }
  },
  actions: {
    async registeredWallet({ commit }, wallet) {
      try {
        const { data: { wallet: response } } = await axios.get(`${baseUrl}/api/wallet?wallet=${wallet}`);
        if (response.wallet) {
          commit('setWalletExistsWhiteList', true)
          commit('setRegisteredUserInformation', response)
        } else {
          commit('setWalletExistsWhiteList', false)
        }
      } catch(error) {
        commit('setError', 'An error ocurred in the registry');
      }
    },
    async registerToList({ commit }, data) {
      try {
        await axios.post(`${baseUrl}/api/wallet`, data);
      }catch(error) {
        commit('setError', 'An error ocurred in the registry');
      }
    },
    async availability({ commit }) {
      try {
       
        let response = await axios.get(`${baseUrl}/api/availability`);
        if(response.status === 200) {
          commit('setPlacesAvailable', response.data.remains);
        }
      }catch(error) {
        commit('setError', 'An error has ocurred');
      }
    },
    async updateRegisteredWallet({ commit }, data) {
      try {
        const response = await axios.put(`${baseUrl}/api/wallet`, data);
        if (response.status ===  201) {
          commit('setIsUpdatePreSale', true);
        } else {
          commit('setIsUpdatePreSale', false);
        }
      } catch(error) {
        commit('setIsUpdatePreSale', false)
        commit('setError', 'An error ocurred in the registry');
      }
    },
  },
  modules: {
  }
})