<template>
  <div>
    <nav id="navbar" class="row navbar margin-desktop-navbar fixed-top d-none d-sm-none d-md-block py-3">
      <div class="row row-desktop-navbar align-items-center align-content-center">
        <div class="col-3">
          <img class="img-desktop-navbar" src="../assets/logo-large.svg" alt="...">
        </div>
        <div class="col-6">
         <ul class="nav nav-pills nav-fill">
            <li class="nav-item" v-for="(itemMenu, index) in menu" :key="index">
              <a class="nav-link link-desktop-navbar text fs-7" @click="moveToSelectedSection(itemMenu.href)">{{ itemMenu.name }}</a>
            </li> 
            <li class="nav-item">
              <a :href="twitterURL" target="_blank">
                <i class="bi bi-twitter"></i>
              </a>
            </li>
            <li class="nav-item">
              <a :href="discordURL" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16">
                  <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                </svg>
              </a>
            </li>
            <!-- <li>
              <a @click="changeLang('en')">EN</a>/<a @click="changeLang('es')">ES</a>
            </li> -->
          </ul>
        </div>
        <div class="col-3 text-end">
          <ConnectWalletButton :label="$t('navbar.connectWallet')" v-model:wallets="wallets" />
        </div>
      </div>
    </nav>
   
    <nav class="row navbar margin-mobile-navbar d-block d-sm-block d-md-none">
      <div class="row row-mobile align-items-center">
        <div class="col-3">
          <img class="img-mobile-navbar" src="../assets/logo-min.svg" alt="...">
        </div>
        <div class="col-9 text-end content-button-mobile-navbar">
          <ConnectWalletButton :label="$t('navbar.connectWallet')" v-model:wallets="wallets" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import ConnectWalletButton from '@/components/ConnectWalletButton'
import utilsMoveToSection from '@/utils/moveToSection';
import { DISCORD_URL, TWITTER_URL } from '@/utils/constants';

export default {
  name: 'AppNavbar',
  components: {ConnectWalletButton},
  data() {
    return {
      wallets: [],
      twitterURL: TWITTER_URL,
      discordURL: DISCORD_URL,
      menu: this.menuLabels(),
    }
  },
  methods: {
    moveToSelectedSection(id) {
      utilsMoveToSection.moveToSection(id);
    },
    changeLang (language) {
      this.$i18n.locale = language
      this.menu = this.menuLabels()
    },
    menuLabels () {
      return [
        { name: this.$t('navbar.welcome'), href: 'storytelling' },
        { name: this.$t('navbar.mint'), href: 'mint' },
        { name: this.$t('navbar.roadmap'), href: 'roadmap' },
        { name: this.$t('navbar.team'), href: 'team' }
      ]
    }

  },
  watch: {
    wallets () {
      this.$emit('wallets', this.wallets);
    }
  }
}
</script>

<style lang="scss">
.navbar {
  background: rgba(7, 13, 13, 0.56) !important;
  border-radius: 8px !important;
  backdrop-filter: blur(24px);
  height: 86px;
  z-index: 999;
}

.nav-pills .nav-link.active {
  background-color: transparent;
}

.margin-desktop-navbar {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.link-desktop-navbar {
  padding: 0;
}

.img-desktop-navbar {
  width: 160px;
}

.row-desktop-navbar {
  margin-right: 0;
  margin-left: 0;
}

.margin-mobile-navbar {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1.75rem;
  margin-bottom: 1rem;
  position: absolute;
  left: 0;
  right: 0;
}

.img-mobile-navbar {
  width: 70px;
}

.content-button-mobile-navbar {
  padding-right: 0;
}

.bi-twitter, .bi-discord {
  font-size: 20px;
}

.bi-twitter {
  position: relative;
  bottom: 2px;
}

.nav-item a[target="_blank"] {
  color: white;
  &:hover {
    color: #0d6efd !important;
  }
}

</style>
