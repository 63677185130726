<template>
  <div>
    <div id="hero" class="hero">
      <div class="scroll">
      <div>
        <img src="../assets/shape_wolf.png" alt="Shape Werewolf" id="shape-wolf">
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  created () {
    this.executeAnimation;
  },
  computed: {
    executeAnimation() {
      let value = null;
      let valuePrevious = null;
      let valueHero = null;
      let valueElement = null;
      let isVisibleWolf= false;
      let moveWolf = null;
     
      window.addEventListener('scroll', () => {
        value = window.scrollY;

        if(!valueElement) {
          valueElement = document.documentElement;
        }

        if(!valueHero) {
          valueHero = document.getElementById('hero');
        }

        let scrolling = document.getElementById('shape-wolf');
        const postTopView = window.scrollY;
        const posButView = postTopView + window.innerHeight;
        const elementTop = scrolling.offsetTop;
        const elementBottom = elementTop + scrolling.offsetHeight
        isVisibleWolf = ((elementBottom < posButView && elementBottom > postTopView) || (elementTop > postTopView && elementTop < posButView));
        
        if (isVisibleWolf) {
          if (value < valuePrevious) { //bajando
            if(moveWolf < 0) {
              moveWolf = -value * 1;
              scrolling.style.left = moveWolf + "px";
            }
          } else { //subiendo
            if (moveWolf > -scrolling.offsetWidth) {
              moveWolf = -value * 1;
              scrolling.style.left = moveWolf + "px";
            }
          }
        }
        
        valuePrevious = value;
        return null
      })
      return null
    }
  }
}
</script>

<style lang="scss" scoped>

  .hero {
    margin-top: 102px;
    width: 100%;
    height: 90vh;
    background: url("../assets/hero.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  @media (max-width: 768px){
    .hero {
      margin-top: 0;
      height: 80vh;
      background: url("../assets/hero.jpeg");
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      z-index: 1;
    }
  }

  #shape-wolf {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;

    
    @media (min-width: 576px){
      width: 400px;
    }

    @media (min-width: 992px) {
      width: 500px;
    }

    @media (min-width: 1200px) {
      width: 600px;
    }

  }
</style>
