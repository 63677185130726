<template>
  <div id="storytelling">
    <div class="container-fluid">
      <div class="row margin-section justify-content-center">
        <div class="col-10 col-md-5 col-lg-5">
          <div class="text fs-2 title">{{ $t('storyTelling.first.title1') }}</div>
          <div class="text fs-2 title text-orange">{{ $t('storyTelling.first.title2') }}</div>
          <p>{{ $t('storyTelling.first.text') }}</p>
        </div>
        <div class="col-10 col-md-5 col-lg-5 text-right">
          <img src="../assets/wolf_sketch.jpeg" alt="wolf1" />
        </div>
      </div>
      <div class="row margin-section justify-content-center">
        <div class="col-10 col-md-5 col-lg-5 order-lg-2 order-md-2">
          <div class="text fs-2 title">{{ $t('storyTelling.second.title') }}</div>
          <p>{{ $t('storyTelling.second.text') }}</p>
        </div>
        <div class="col-10 col-md-5 col-lg-5 order-lg-1 order-md-1">
          <img src="../assets/wolf_6.jpg" alt="wolf6" />
        </div>
      </div>
       <div class="row margin-section justify-content-center">
        <div class="col-10 col-md-5 col-lg-5">
          <div class="text fs-2 title">{{ $t('storyTelling.third.title') }}</div>
          <p>{{ $t('storyTelling.third.text') }}</p>
        </div>
        <div class="col-10 col-md-5 col-lg-5 text-right">
          <img src="../assets/wolf_7.jpg" alt="wolf7" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'storytelling',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
  .margin-section {
    padding-top: 122px;
  }

  img {
    width: 70%;
    border-radius: 8px;

  }

  .text-right {
    text-align: right;
  }

  .title {
    font-family: 'NotoSans';
    font-weight: bold;
  }

  p {
    margin-top: 24px;
    text-align: justify;
  }

  @media (max-width: 768px) {
    #storytelling {
      padding-bottom: 75px;
    }

    .margin-section {
      padding-top: 56px;
    }

    p {
      font-size: 14px;
      padding-top: 16px;
      padding-bottom: 24px;
      margin-top: 0;
    }

    .title {
      font-family: 'NotoSans';
      font-weight: bold;
      font-size: 24px !important;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
</style>
